import React, { Component } from "react"
import { Link } from "react-router-dom"
import styles from "./scss/Home.module.scss"

import Title from "partial/Title"
// import FAQLink from "partial/Link"
import Carousel from "partial/Carousel"
import Loader from "partial/Loader"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actions from "./redux/actions"
import * as actionsBanner from "base/redux/actions"

class Home extends Component {
  componentDidMount() {
    const { actions, bannerActions } = this.props
    actions.get_top_issues()
    actions.get_social_media()
    bannerActions.put_banner(require("assets/img/Banner/Header_BG.jpg"))
  }

  populateGame = () => {
    const { games } = this.props

    if (!games) {
      return <Loader />
    }

    return games.map?.((data, index) => {
      return (
        <Link key={index} to={`/${data.slug}/faq`} className={styles.game}>
          <div
            style={{
              background: `url(${
                process.env.REACT_APP_CDN_URL + data.logo?.filename_disk
              }) center/contain no-repeat`,
            }}
          />
        </Link>
      )
    })
  }

  render() {
    const { top_issue } = this.props.home
    const { translation, social_media } = this.props

    return (
      <div id="Home" className="container pt-5 pb-5">
        <div className="row">
          <div className={`col-md-6 col-12 ${styles.content__container__left}`}>
            <div className="top">
              <Title>{translation?.txt_top_issue}</Title>
              <div>
                <Carousel data={top_issue} other="/all/list/top issue" />
                {/* <FAQLink other="/all/list/top issue" data={top_issue} /> */}
              </div>
            </div>
            <div className={styles.bottom}>
              <Title>{translation?.txt_contact_us}</Title>
              <div className={styles.contact_us}>
                <Link to="/ticket/create">
                  <span>{translation?.txt_other_question_about_game}</span>
                  <span>▶</span>
                </Link>
              </div>
              <div className={styles.social_cont}>
                {social_media?.list?.map((dt, idx) => (
                  <a
                    className={styles.social_icons}
                    href={dt.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={idx}
                  >
                    <img
                      src={
                        process.env.REACT_APP_CDN_URL + dt.image.filename_disk
                      }
                      alt=""
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className={`col-md-6 col-12`}>
            <Title>{translation?.txt_game_assistance}</Title>
            <div className={`${styles.content__container__right}`}>
              <Link to={`/ticket/create`} className={styles.game}>
                <div
                  style={{
                    background: `url(${
                      process.env.REACT_APP_CDN_URL +
                      translation?.img_home_btn_support?.filename_disk
                    }) center/contain no-repeat`,
                  }}
                />
              </Link>
              {this.populateGame()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    games: state.main.games,
    translation: state.main.translation,
    social_media: state.main.social_media,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    bannerActions: bindActionCreators(actionsBanner, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
