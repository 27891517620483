import React, { Component } from "react"
import { Link } from "react-router-dom"
import Dropdown from "react-dropdown"
import ReactGA from "react-ga4"

import LoginModal from "partial/LoginModal"

import "react-dropdown/style.css"
import styles from "./scss/Navbar.module.scss"
import clsx from "clsx"

const getUrlParam = (pa) => {
  let url = window.location.href.replace(/#+.*$/),
    params = url.substring(url.indexOf("?") + 1, url.length).split("&"),
    param = {}

  for (let i = 0; i < params.length; i++) {
    let pos = params[i].indexOf("="),
      key = params[i].substring(0, pos),
      val = params[i].substring(pos + 1)

    param[key] = val
  }

  return typeof param[pa] === "undefined" ? false : param[pa]
}

const removeParamUrl = () => {
  const location = window.location
  window.history.pushState(
    {},
    document.title,
    location.origin + location.pathname
  )
}

const lang_list = [
  { value: "ar", label: "عربي" },
  { value: "en", label: "English" },
  { value: "tr", label: "Türkçe" },
]

class Navbar extends Component {
  state = {
    open: false,
    is_open: false,
    active_lang: window.localStorage.getItem("lang") || "en",
  }

  toggleModal = (e) => {
    this.setState({
      open: !this.state.open,
    })
  }

  toggle_menu = (data) => {
    this.setState({
      is_open: data,
    })
  }

  handleLogout = () => {
    const ls = window.localStorage
    let token = ls.token
    ls.clear()
    window.location.href = `${process.env.REACT_APP_API_ENDPOINT}support/logout/?token=${token}`
  }

  componentDidMount() {
    let current_lang = this.state.active_lang
    if (getUrlParam("lang")) {
      current_lang =
        lang_list.find((e) => e.value === getUrlParam("lang"))?.value ||
        current_lang
      removeParamUrl()
    }

    window.localStorage.setItem("lang", current_lang)
    document.getElementsByTagName("html")[0].setAttribute("lang", current_lang)
    this.setState({
      active_lang: current_lang,
    })

    // console.log(this.state.active_lang)
    // window.localStorage.setItem("lang", this.state.active_lang)
    // document.getElementsByTagName("html")[0].setAttribute("lang", this.state.active_lang)
  }

  handleLang = (lang) => {
    const { actions, translation } = this.props

    if (this.state.active_lang !== lang) {
      if (window.location.pathname === "/ticket/create") {
        this.setState({
          active_lang: lang_list.find((e) => e.value !== lang).value + " ",
        })
        setTimeout(() => {
          this.setState({
            active_lang: lang_list.find((e) => e.value !== lang).value,
          })
        }, 1)

        actions.put_alert_content(
          translation?.txt_warning_refresh_title,
          translation?.txt_warning_refresh_content
        )
        actions.toggleAlert("refresh")
      } else {
        window.localStorage.setItem("lang", lang)
        window.location.reload()
      }
    }
  }

  handleTopupClick = () => {
	const { translation } = this.props;
	ReactGA.event({
		category: 'Topup',
		action: 'Topup Click',
	});
	setTimeout(() => {
		window.open(translation?.topup_btn_link)
	}, 300);
  }

  renderTopup = () => {
		const { translation } = this.props;
		return (
			<div
				onClick={this.handleTopupClick}
				className={clsx(styles.topup_btn)}
				target="_blank"
				rel="noopener noreferrer"
			>
				{translation?.txt_topup_btn}
			</div>
		);
	};

  render() {
    const { open, is_open, active_lang } = this.state
    const { data, translation } = this.props

    return (
      <div id="Navbar" className={styles.navbar}>
        <div className={`${styles.desktop}`}>
          <div className={styles.desktop__left}>
            <Link to="/">
              <img
                src={
                  process.env.REACT_APP_CDN_URL +
                  translation?.img_header_logo?.filename_disk
                }
                alt=""
              />
            </Link>
          </div>
          <div className={styles.desktop__right}>
            {this.renderTopup()}
            <div className={styles.desktop__right__lang_toggle}>
              <Dropdown
                className={styles.dropdown}
                controlClassName={styles.dropdown__control}
                arrowClassName={styles.dropdown__control__arrow}
                options={lang_list}
                value={active_lang || ""}
                onChange={(e) => this.handleLang(e.value)}
              />
            </div>
            {data ? (
              <>
                <Link to="/ticket/create">
                  {translation?.txt_header_ticket}
                </Link>
                <span onClick={this.handleLogout}>
                  {data.user.nickname} ( {translation?.txt_header_logout} )
                </span>
              </>
            ) : (
              <span onClick={this.toggleModal}>
                {translation?.txt_header_login}
              </span>
            )}
          </div>
        </div>

        <div className={styles.mobile}>
          <Link to="/">
            <img src={require("assets/img/Logo.png")} alt="" />
          </Link>

          {this.renderTopup()}
          
          <Dropdown
            className={styles.dropdown}
            controlClassName={styles.dropdown__control}
            arrowClassName={styles.dropdown__control__arrow}
            options={lang_list}
            value={active_lang || ""}
            onChange={(e) => this.handleLang(e.value)}
          />


          <div
            className={`${styles.hamburger} hamburger hamburger--spin ${
              is_open ? `is-active` : ``
            }`}
            onClick={() => this.toggle_menu(!is_open)}
          >
            <span className={`${styles.hamburger_box} hamburger-box`}>
              <span
                className={`${styles.hamburger_inner} hamburger-inner`}
              ></span>
            </span>
          </div>

          <div
            className={`${styles.overlay_menu} ${
              is_open ? styles.overlay_menu_active : ``
            }`}
            onClick={() => this.toggle_menu(false)}
          >
            <div
              className={`${styles.nav_menu} ${
                is_open
                  ? this.state.active_lang === "ar"
                    ? styles.nav_menu_active_ar
                    : styles.nav_menu_active
                  : ``
              } ${this.state.active_lang === "ar" && styles.nav_menu_ar}`}
            >
              <ul>
                <li>
                  <Link to="/" onClick={() => this.toggle_menu(false)}>
                    {translation?.txt_home}
                  </Link>
                </li>
                {data ? (
                  <>
                    <li>
                      <Link
                        to="/ticket/create"
                        onClick={() => this.toggle_menu(false)}
                      >
                        {translation?.txt_header_ticket}
                      </Link>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          this.toggle_menu(false)
                          this.handleLogout()
                        }}
                      >
                        {data.user.nickname}
                        <br />( {translation?.txt_header_logout} )
                      </span>
                    </li>
                  </>
                ) : (
                  <li>
                    <span onClick={this.toggleModal}>
                      {translation?.txt_header_login}
                    </span>
                  </li>
                )}
                {/* <li>
									{lang_list.map((dt, idx) => (
										<span
											key={idx}
											value={dt.value}
											onClick={() => this.handleLang(dt.value)}
											className={active_lang !== dt.value ? styles.desktop__right__lang_toggle__active : ""}
										>
											{dt.label}
										</span>
									))}
								</li> */}
              </ul>
            </div>
          </div>
        </div>

        <LoginModal open={open} closeModal={this.toggleModal} />
      </div>
    )
  }
}

export default Navbar
