import React, { Component } from "react"
import styles from "./scss/Banner.module.scss"
import { connect } from "react-redux"

import Search from "partial/Search"

class Banner extends Component {
  handleImage = () => {
    const { data, translation } = this.props
    const pathname = window.location.pathname

		if (data) {
			let filtered = data.filter((dt) => pathname.indexOf(dt.slug) > -1)
			if (filtered.length > 0) {
				return process.env.REACT_APP_CDN_URL + filtered[0]?.banner?.filename_disk 
			}
		}

    return (
      process.env.REACT_APP_CDN_URL +
      translation?.img_general_banner?.filename_disk
    )
  }

  render() {
    return (
      <div
        id="Banner"
        className={styles.bannerParent}
        style={{ backgroundImage: `url(${this.handleImage()})` }}
      >
        <Search />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { translation: state.main.translation }
}

export default connect(mapStateToProps)(Banner)
