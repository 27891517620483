import React, { Component } from "react"
import { connect } from "react-redux"
import Slider from "react-slick"
import Loader from "partial/Loader"
import { Link } from "react-router-dom"
import style from "./scss/Link.module.scss"

const ls = window.localStorage

class index extends Component {
  populateLink = () => {
    const { data, other, translation } = this.props

    const get_localize = (data) => {
      return data.translations.find?.((e) => e.languages_code.code === ls.lang)
    }

    if (!data) {
      return <Loader />
    } else if (data.length < 1) {
      return <div className={style.other}>Data tidak ditemukan</div>
    } else {
      return (
        <>
          <Slider
            {...{
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 10000,
              adaptiveHeight: true,
            }}
          >
            {data.map(
              (data, index) =>
                data.game &&
                get_localize(data) && (
                  <Link
                    className={style.link}
                    key={index}
                    to={`/${data.game.slug}/post/${data.slug}`}
                  >
                    <div>
                      <img
                        src={
                          get_localize(data)?.featured_image
                            ? process.env.REACT_APP_CDN_URL +
                              get_localize(data)?.featured_image.filename_disk
                            : "https://via.placeholder.com/1280x720.jpg?text=x"
                        }
                        alt=""
                      />
                      <span>{get_localize(data)?.title}</span>
                    </div>
                  </Link>
                )
            )}
          </Slider>

          {other && (
            <Link className={style.other} key={"other"} to={other}>
              {translation?.txt_see_more}
            </Link>
          )}
        </>
      )
    }
  }

  render() {
    return <>{this.populateLink()}</>
  }
}

function mapStateToProps(state) {
  return { translation: state.main.translation }
}

export default connect(mapStateToProps)(index)
