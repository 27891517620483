import React, { Component } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { connect } from "react-redux"

import styles from "./scss/Card.module.scss"

class Card extends Component {
  renderCard = () => {
    const {
      ticketNo,
      date,
      game,
      category,
      sub_category,
      status,
      openModal,
      review,
      last_reply_by,
      is_review,
      put_ticket_number,
      translation,
    } = this.props

    let is_mobile = window.screen.width < 700 ? "col-12" : "col"

    return (
      <div id="Card" className={styles.card}>
        <div className={`row`}>
          <div className={`col p-0 pb-2 ${styles.card__date}`}>
            {moment(date).format("DD/MM/YYYY")}
          </div>
        </div>
        <Link
          onClick={() => put_ticket_number(ticketNo)}
          to={`/ticket/list/${ticketNo}`}
          className={`row mt-2 ${styles.card__row}`}
        >
          <div className={`${is_mobile} ${styles.card__col}`}>
            <div className={`pb-3 ${styles.card__title}`}>
              {translation?.txt_ticket}
            </div>
            <div className={`${styles.card__content}`}>{ticketNo}</div>
          </div>
          <div className={`${is_mobile} ${styles.card__col}`}>
            <div className={`pb-3 ${styles.card__title}`}>
              {translation?.txt_game}
            </div>
            <div className={`${styles.card__content}`}>{game}</div>
          </div>
          <div className={`${is_mobile} ${styles.card__col}`}>
            <div className={`pb-3 ${styles.card__title}`}>
              {translation?.txt_category}
            </div>
            <div className={`${styles.card__content}`}>{category}</div>
          </div>
          <div className={`${is_mobile} ${styles.card__col}`}>
            <div className={`pb-3 ${styles.card__title}`}>
              {translation?.txt_sub_category}
            </div>
            <div className={`${styles.card__content}`}>{sub_category}</div>
          </div>
          <div className={`${is_mobile} ${styles.card__col}`}>
            <div className={`pb-3 ${styles.card__title}`}>
              {translation?.txt_status}
            </div>
            <div className={`${styles.card__content}`}>{status}</div>
          </div>
          {last_reply_by !== "User" && !is_review && (
            <div className={styles.card__notif}>{translation?.txt_new}</div>
          )}
        </Link>
        {review && (
          <div
            className={`${styles.card__review__btn} ${
              window.localStorage.lang === "ar" && styles.card__review__btn_ar
            }`}
            onClick={() => {
              put_ticket_number(ticketNo)
              openModal()
            }}
          >
            {translation?.txt_review}
          </div>
        )}
      </div>
    )
  }
  render() {
    return this.renderCard()
  }
}

function mapStateToProps(state) {
  return { translation: state.main.translation }
}

export default connect(mapStateToProps)(Card)
