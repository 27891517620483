import React, { Component } from "react"
import { Link } from "react-router-dom"
import Loader from "partial/Loader"
import { connect } from "react-redux"

import style from "./scss/Link.module.scss"

const ls = window.localStorage

class FAQLink extends Component {
  populateLink = () => {
    const { data, other, translation } = this.props

    const get_localize = (data) => {
      return data.translations.find?.((e) => e.languages_code === ls.lang)
    }

    if (!data) {
      return <Loader />
    }

    let list = []
    if (data.length < 1) {
      return <div className={style.other}>{translation?.txt_no_data}</div>
    }

    data.map((data, index) => {
      if (data.game && get_localize(data)) {
        list.push(
          <Link
            className={style.link}
            key={index}
            to={`/${data.game.slug}/post/${data.slug}`}
          >
            <span>{get_localize(data)?.title}</span>
            <span>&#9660;</span>
          </Link>
        )
      }
      return list
    })

    if (other) {
      list.push(
        <Link className={style.other} key={"other"} to={other}>
          {translation?.txt_see_more}
        </Link>
      )
    }

    return list
  }

  render() {
    return <>{this.populateLink()}</>
  }
}

function mapStateToProps(state) {
  return { translation: state.main.translation }
}

export default connect(mapStateToProps)(FAQLink)
