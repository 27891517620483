import { directus, otrs } from "wrapper"
import { toggleAlert, put_alert_content, map_err } from "base/redux/actions"

const put_data = (key, data) => ({
  type: "PUT_DATA",
  key,
  data,
})

export const get_category_article = (slug, type) => {
  return (dispatch) => {
    dispatch(put_data(type, false))
    directus
      .get(
        `items/articles?limit=6&fields=*.*.*&filter[status]=published&filter[_and][0][_and][0][game][slug][_eq]=${slug}&filter[_and][0][_and][0][type][slug][_eq]=${type}&sort=-date_created&filter[_and][0][_and][0][translations][languages_code][code][_eq]=${window.localStorage.lang}`
      )
      .then((resp) => {
        dispatch(put_data(type, resp))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const get_article = (slug) => {
  return (dispatch) => {
    directus
      .get(`items/articles?fields=*.*.*&filter[slug]=${slug}&sort=-date_created&filter[_and][0][_and][0][translations][languages_code][code][_eq]=${window.localStorage.lang}`)
      .then((resp) => {
        dispatch({
          type: "PUT_ARTICLE",
          data: slug === "clear" ? undefined : resp,
        })
        dispatch(check_vote_article(resp[0].id))
        // dispatch(increment_view(resp.id, resp.view_count))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const increment_view = (id, count) => {
  return (dispatch) => {
    directus
      .patch(`items/faq/${id}`, {
        view_count: count + 1,
      })
      .then((resp) => {})
      .catch((err) => {
        console.log(err)
      })
  }
}

export const vote_article = (data) => {
  return (dispatch, getState) => {
    const { translation } = getState().main

    otrs
      .post("support/vote_article/", data)
      .then((resp) => {
        dispatch(
          put_alert_content(
            translation?.txt_popup_alert_title_success,
            translation?.txt_review_article_success
          )
        )
        dispatch(toggleAlert())
        dispatch(check_vote_article(data.get("article_id")))
      })
      .catch((err) => {
        dispatch(map_err(err))
      })
  }
}

export const err = () => {
  return (dispatch) => {
    otrs
      .get("err/")
      .then((resp) => {})
      .catch((err) => {
        console.log(err)
      })
  }
}

export const check_vote_article = (article_id) => {
  return (dispatch) => {
    otrs
      .get(`support/check_vote_article/${article_id}`)
      .then((resp) => {
        dispatch({
          type: "PUT_IS_VOTE",
          data: resp.is_already_vote,
        })
      })
      .catch((err) => {
        dispatch({
          type: "PUT_IS_VOTE",
          data: false,
        })
      })
  }
}
