import React, { Component } from "react"
import Modal from "react-responsive-modal"
import { connect } from "react-redux"

import styles from "./scss/AlertModal.module.scss"

class AlertModal extends Component {
  render() {
    const { open, closeModal, title, content, context, translation } =
      this.props

    const handleRefresh = () => {
      switch (window.localStorage.lang) {
        case "en":
          window.localStorage.setItem("lang", "ar")
          break
        case "ar":
          window.localStorage.setItem("lang", "en")
          break
        default:
          break
      }
      window.location.reload()
    }

    return (
      <Modal
        open={open}
        onClose={() => closeModal()}
        center
        focusTrapped={false}
        classNames={{
          modal: styles.modal,
          closeButton: styles.closeBtn,
        }}
      >
        <div className={styles.modalCont}>
          <div className={styles.title}>{title}</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: content ? content : "Silahkan Refresh Browser Anda",
            }}
          ></div>

          {context === "refresh" && (
            <div className={styles.buttons}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => closeModal()}
              >
                {translation?.txt_no}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleRefresh()}
              >
                {translation?.txt_yes}
              </button>
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return { translation: state.main.translation }
}

export default connect(mapStateToProps)(AlertModal)
