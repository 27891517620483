import axios from "axios"
import { otrs, directus } from "wrapper"
import { toggleAlert, put_alert_content } from "base/redux/actions"

export const toggle_modal_review = () => ({
  type: "TOGGLE_MODAL_REVIEW",
})

export const toggle_modal_detail = () => ({
  type: "TOGGLE_MODAL_DETAIL",
})

export const toggle_modal_submit = () => ({
  type: "TOGGLE_MODAL_SUBMIT",
})

export const put_ticket_number = (data) => ({
  type: "PUT_TICKET_NUMBER",
  data,
})

export const put_list_file = (data) => ({
  type: "PUT_LIST_FILE",
  data,
})

export const put_form_data = (data) => ({
  type: "PUT_FORM_DATA",
  data,
})

export const put_clear_form_data = (data) => ({
  type: "CLEAR_FORM_DATA",
  data,
})

export const put_ticket_detail = (data) => ({
  type: "PUT_TICKET_DETAIL",
  data,
})

export const append_chat = (data) => {
  return (dispatch) => {
    dispatch({
      type: "APPEND_CHAT",
      data,
    })

    const el = document.getElementById("conversation")
    el.scrollTop = el.scrollHeight
  }
}

export const get_categories = () => {
  return (dispatch) => {
    otrs
      .get(`otrs/categories/?lang=${window.localStorage.lang}`)
      .then((resp) => {
        dispatch({
          type: "PUT_CATEGORIES",
          data: resp,
        })
      })
      .catch((err) => {})
  }
}

export const get_feedback_list = (rate) => {
  return (dispatch) => {
    otrs
      .get(`support/feedback_list/${rate}/`)
      .then((resp) => {
        dispatch({
          type: "PUT_LIST_FEEDBACK",
          data: resp.feedback_list,
        })
      })
      .catch((err) => {})
  }
}

export const show_alert = (title, msg) => {
  return (dispatch) => {
    dispatch(put_alert_content(title, msg))
    dispatch(toggleAlert())
  }
}

export const create_ticket = (data, callback = false) => {
  return (dispatch, getState) => {
    const { translation } = getState().main

    otrs
      .post("support/create_ticket/", data)
      .then((resp) => {
        if (callback) {
          callback()
        }

        dispatch(put_clear_form_data(true))
        dispatch(
          put_alert_content(
            translation?.txt_popup_alert_title_success,
            resp.msg
          )
        )
      })
      .catch((err) => {
        if (err.data) {
          let content = `<div style="text-align: center; margin-bottom: 1vw;">${err.data.msg}</div>`

          if (err.data.details) {
            content += "<ul>"

            if (Array.isArray(err.data.details)) {
              err.data.details.forEach((el) => {
                content += `<li>${el}</li>`
              })
            } else {
              content += `<li>${err.data.details}</li>`
            }

            content += "</ul>"
          }

          dispatch(
            put_alert_content(translation?.txt_popup_alert_title_error, content)
          )
        }
      })
      .then(() => {
        dispatch(toggle_modal_submit())
        dispatch(toggleAlert())
      })
  }
}

export const upload_file = (
  data,
  onUploadProgress,
  callbackSuccess = () => {},
  callbackError = () => {},
  i
) => {
  const storage = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://cdn.support.garena.com/api/upload/"
        : "http://124.158.142.45:2101/obs/api/upload/",
    withCredentials: true,
    headers: {
      [process.env.REACT_APP_TOKEN_HEADER_NAME]:
        window.localStorage.getItem("token"),
    },
  })

  // const storage = axios.create({
  //     baseURL: Math.random() < 0.5 ? 'https://dickymr.com/' : 'https://storage.support.garena.co.id/',
  //     withCredentials: true
  // })

  return (dispatch, getState) => {
    const { translation } = getState().main

    storage
      .post("", data, { onUploadProgress })
      .then((resp) => {
        if (resp.data.status === false) {
          callbackError()
          dispatch(
            put_alert_content(
              translation?.txt_popup_alert_title_error,
              resp.data.message
            )
          )
          dispatch(toggleAlert())
        } else {
          callbackSuccess(resp.data.message, data.get("filename"))
        }
      })
      .catch((err) => {
        console.log(err)
        callbackError(i)
        // document.getElementById('fileUpload').value = "";
      })
  }
}

export const survey = (ticket_number, convo_id, choice) => {
  return (dispatch) => {
    otrs
      .post(`support/survey/${ticket_number}/${convo_id}/${choice}/`)
      .then((resp) => {
        dispatch(ticket_detail(ticket_number))
      })
      .catch((err) => {
        console.log(err)
        // show_alert(err, )
      })
  }
}

export const put_current_page = (data) => ({
  type: "PUT_CURRENT_PAGE",
  data,
})

export const ticket_detail = (ticket_number) => {
  return (dispatch) => {
    otrs
      .get(`support/ticket_detail/${ticket_number}/`)
      .then((resp) => {
        dispatch({
          type: "PUT_TICKET_DETAIL",
          data: resp,
          conv: resp.ticket_conversation,
        })

        const el = document.getElementById("conversation")
        el.scrollTop = el.scrollHeight
      })
      .catch((err) => {})
  }
}

export const ticket_list = (page, is_closed = 0) => {
  return (dispatch) => {
    dispatch({
      type: "PUT_TICKET_LIST",
      data: false,
    })

    dispatch(put_current_page(page))

    otrs
      .get(`support/ticket_list/?page=${page}&is_closed=${is_closed}&lang=ar`)
      .then((resp) => {
        dispatch({
          type: "PUT_TICKET_LIST",
          data: resp.data,
        })

        dispatch({
          type: "PUT_TOTAL_PAGE",
          data: resp.total_page,
        })
      })
      .catch((err) => {
        dispatch({
          type: "PUT_TICKET_LIST",
          data: false,
        })
      })
  }
}

export const add_conversation = (
  ticket_number,
  data,
  callback = () => {},
  callbackError = () => {}
) => {
  return (dispatch, getState) => {
    const { translation } = getState().main
    const el = document.querySelector('textarea[name="content"]')

    el.setAttribute("disabled", true)

    otrs
      .post(`support/add_conversation/${ticket_number}/`, data)
      .then((resp) => {
        dispatch(ticket_detail(ticket_number))
        callback()
      })
      .catch((err) => {
        if (err.data) {
          dispatch(
            put_alert_content(
              translation?.txt_popup_alert_title_error,
              err.data.msg
            )
          )
          dispatch(toggleAlert())
        }
        callbackError()
      })
      .then(() => {
        el.removeAttribute("disabled")
      })
  }
}

export const submit_rating = (ticket_number, data, callback = false) => {
  return (dispatch, getState) => {
    const { translation } = getState().main

    otrs
      .post(`support/submit_rating/${ticket_number}/`, data)
      .then((resp) => {
        dispatch(ticket_list(1, 1))
        dispatch(ticket_detail(ticket_number))
        dispatch(toggle_modal_review())
        dispatch(
          put_alert_content(
            translation?.txt_popup_alert_title_success,
            translation?.txt_review_article_success
          )
        )
        dispatch({
          type: "PUT_LIST_FEEDBACK",
          data: [],
        })
      })
      .catch((err) => {
        let msg = ""
        if (err.data) {
          msg = `<div class="mb-3">${err.data.msg}</div>`

          if (err.data.details) {
            let details = err.data.details
            details.forEach((dt) => {
              msg += `<div>${dt}</div>`
            })
          }
        }
        dispatch({
          type: "PUT_LIST_FEEDBACK",
          data: [],
        })

        dispatch(
          put_alert_content(translation?.txt_popup_alert_title_error, msg)
        )
      })
      .then(() => {
        dispatch(toggleAlert())
        if (callback) {
          callback()
        }
      })
  }
}

export const get_tooltip = () => {
  return (dispatch) => {
    directus
      .get("items/tooltip/")
      .then((resp) => {
        dispatch({
          type: "PUT_TOOLTIP",
          data: resp,
        })
      })
      .catch((err) => {})
  }
}

export const close_ticket = (ticket_number) => {
  return (dispatch, getState) => {
    const { translation } = getState().main

    otrs
      .post(`support/close_ticket/${ticket_number}/`)
      .then((resp) => {
        dispatch(
          put_alert_content(
            translation?.txt_popup_alert_title_success,
            "Tiket kamu sudah ditutup"
          )
        )
        window.location.href = process.env.REACT_APP_BASE_URL + "ticket/list"
      })
      .catch((err) => {
        if (err.data) {
          dispatch(
            put_alert_content(
              translation?.txt_popup_alert_title_error,
              err.data.msg
            )
          )
        } else {
          dispatch(
            put_alert_content(
              translation?.txt_popup_alert_title_error,
              "Silahkan Refresh Browser Anda"
            )
          )
        }
      })
      .then(() => {
        dispatch(toggleAlert())
      })
  }
}
