import React, { useEffect, useState } from "react"
import Modal from "react-responsive-modal"
import styles from "./scss/Submit.module.scss"

import byteSize from "byte-size"
import { useSelector } from "react-redux"

const LOADER = require("assets/img/loader.svg")
const ICON_FILE = require("assets/img/Icon/icon_file.svg")

const SubmitModal = (props) => {
  const { translation } = useSelector((state) => state.main)

  const formatName = (file) => {
    let filename = file.get("filename")
    const isNameTooLong = filename.length > 15
    filename = isNameTooLong
      ? "..." + filename.substring(filename.length - 12)
      : filename

    const bytes = file.get("cvfile").size
    const size = byteSize(bytes)

    return `${filename} (${size.value} ${size.unit})`
  }

  // This function is used to upload a single file.
  const uploadFile = (list, i) => {
    return new Promise((resolve) => {
      if (progresses[i] === 100) {
        resolve()
        return
      }

      setLoaders((loaders) => {
        const newLoaders = [...loaders]
        newLoaders.push(i)
        return newLoaders
      })

      handleUpload(
        list,
        // Progress upload
        (event) => {
          const progress = Math.round((100 * event.loaded) / event.total)

          setProgresses((prevValue) => {
            const newProgresses = [...prevValue]
            newProgresses[i] = progress
            return newProgresses
          })
        },
        // Callback success
        (url, name) => {
          setUrls((urls) => {
            const newUrls = [...urls]
            newUrls[i] = url
            return newUrls
          })

          setFilenames((filenames) => {
            const newFilenames = [...filenames]
            newFilenames[i] = name
            return newFilenames
          })

          setLoaders((loaders) => {
            const newLoaders = [...loaders]
            return newLoaders.filter((loader) => loader !== i)
          })

          resolve()
        },
        // Callback error
        () => {
          setProgresses((prevValue) => {
            const newProgresses = [...prevValue]
            newProgresses[i] = "error"
            return newProgresses
          })

          setLoaders((loaders) => {
            const newLoaders = [...loaders]
            return newLoaders.filter((loader) => loader !== i)
          })
        }
      )
    })
  }

  // This function is used to upload multiple files.
  const uploadFiles = async () => {
    if (listFile.length < 1) return

    await Promise.all(listFile.map(uploadFile))
  }

  const handleReupload = async () => {
    await uploadFiles()
  }

  const { open, closeModal, listFile, formdata, handleUpload, handleSubmit } =
    props

  const [progresses, setProgresses] = useState([])
  const [loaders, setLoaders] = useState([])
  const [urls, setUrls] = useState([])
  const [filenames, setFilenames] = useState([])

  const showLoader = loaders.length > 0
  const isError = (i) => progresses[i] === "error"
  const isContainError = progresses.includes("error") && !showLoader

  useEffect(() => {
    ;(async () => await uploadFiles())()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (listFile.length !== urls.length) return
    if (listFile.length !== filenames.length) return
    if (urls.includes(undefined)) return
    if (filenames.includes(undefined)) return

    formdata.append("files", urls.join(", "))
    formdata.append("filename", filenames.join(", "))

    handleSubmit(formdata)

    // eslint-disable-next-line
  }, [urls, filenames, progresses])

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      center
      classNames={{ modal: styles.modal }}
      showCloseIcon={false}
      focusTrapped={false}
      closeOnOverlayClick={false}
    >
      <h5>{translation?.txt_processing}</h5>
      <p className="mb-3">{translation?.txt_dont_close_browser}</p>
      <div className={`${styles.uploadCont} mb-3`}>
        {listFile.map((file, i) => (
          <div key={i} className={`${styles.upload} mb-2`}>
            <div className={`${styles.left}`}>
              <img src={ICON_FILE} alt="" />
            </div>
            <div className={`${styles.right}`}>
              <div className={`${styles.name} mb-1`}>{formatName(file)}</div>
              <div className={`${styles.progressCont}`}>
                <div className={`${styles.progress} progress`}>
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                    role="progressbar"
                    style={{ width: `${progresses[i]}%` }}
                    aria-valuenow={progresses[i]}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                {isError(i) ? (
                  <div className={`${styles.value} ${styles.errorMsg}`}>
                    {translation?.txt_upload_failed}
                  </div>
                ) : (
                  <div className={`${styles.value}`}>{progresses[i] || 0}%</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`${styles.loaderCont}`}>
        {isContainError ? (
          <button
            type="button"
            className="btn btn-danger mt-2"
            onClick={handleReupload}
          >
            {translation?.txt_retry_upload}
          </button>
        ) : (
          <img src={LOADER} alt="" />
        )}
      </div>
    </Modal>
  )
}

export default SubmitModal
