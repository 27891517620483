import React, { Component } from "react"

// REDUX
import { connect } from "react-redux"
import style from "./scss/Search.module.scss"

class Search extends Component {
  handleSearch = (e) => {
    let val = e.currentTarget.value
    if (e.key === "Enter") {
      window.location.href = process.env.REACT_APP_BASE_URL + `search/${val}`
    }
  }

  render() {
    const { translation } = this.props

    return (
      <div id="Search" className={style.searchCont}>
        <div className={style.magnifier}>
          <img src={require("assets/img/Icon/Icon_Search.png")} alt="" />
        </div>
        <input
          type="text"
          placeholder={translation?.txt_search_bar_placeholder}
          onKeyUp={this.handleSearch}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { translation: state.main.translation }
}

export default connect(mapStateToProps)(Search)
