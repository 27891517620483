import React, { Component } from "react"

import styles from "./scss/Login.module.scss"

// REDUX
import { connect } from "react-redux"

class Login extends Component {
  handleLogin = (e, login_type) => {
    e.preventDefault()
    const href = window.location.href
    window.localStorage.setItem("last_page", href)
    window.location.href =
      process.env.REACT_APP_API_ENDPOINT + `support/login/${login_type}`
  }

  render() {
    const { translation } = this.props

    return (
      <div className={`container pt-5 pb-5 text-center ${styles.container}`}>
        <p className={styles.title}>{translation?.txt_pick_login_method}</p>
        <div className={`${styles.btnWrapper} pt-3`}>
          {/* <a href="/" className={`${styles.btn}`} onClick={(e) => this.handleLogin(e, "garena")}>
						<img src={require("assets/img/Icon/icon_garena.png")} alt="" />
					</a> */}
          <a
            href="/"
            className={`${styles.btn}`}
            onClick={(e) => this.handleLogin(e, "fb")}
          >
            <img src={require("assets/img/Icon/ico-fb.png")} alt="" />
          </a>
          <a
            href="/"
            className={`${styles.btn}`}
            onClick={(e) => this.handleLogin(e, "google")}
          >
            <img src={require("assets/img/Icon/icon_google.png")} alt="" />
          </a>
          <a
            href="/"
            className={`${styles.btn}`}
            onClick={(e) => this.handleLogin(e, "vk")}
          >
            <img src={require("assets/img/Icon/ico-vk.png")} alt="" />
          </a>
          <a
            href="/"
            className={`${styles.btn}`}
            onClick={(e) => this.handleLogin(e, "apple")}
          >
            <img src={require("assets/img/Icon/icon_apple.png")} alt="" />
          </a>
          <a
            href="/"
            className={`${styles.btn}`}
            onClick={(e) => this.handleLogin(e, "twitter")}
          >
            <img src={require("assets/img/Icon/icon_twitter.png")} alt="" />
          </a>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { translation: state.main.translation }
}

export default connect(mapStateToProps)(Login)
