import { directus } from "wrapper"

export const get_top_issues = () => {
	return (dispatch) => {
		directus
			.get(
				`items/articles?limit=5&fields=*.*.*&filter[status]=published&filter[is_top_issue]=1&sort=-date_created&filter[_and][0][_and][0][translations][languages_code][code][_eq]=${window.localStorage.lang}`
			)
			.then((resp) => {
				dispatch({
					type: "PUT_TOP_ISSUES",
					data: resp,
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const get_social_media = () => {
	return (dispatch) => {
		directus.get(`items/general?fields=*.*.*.*&deep[social_media][_filter][languages_code][code][_eq]=${window.localStorage.lang}`).then((resp) => {
			dispatch({
				type: "PUT_SOCIAL_MEDIA",
				data: resp.social_media.find((e) => e.languages_code.code === window.localStorage.lang),
			})
		})
	}
}
